import { graphql } from 'gatsby'
import React from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import Layout from '../components/layout'
import Seo from "../components/seo"

const GalleryImages = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
  <Layout>
    <Seo title="Gallery" />
    <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
          <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-8">Photo Gallery</h2>
          <span className="leading text-gray-80 text-center block">Feel free to view the photo gallery below. Check back often we add images as soon as we can!</span>
        </div>
    </div>
    <Gallery images={images} />
  </Layout>)
}

export const pageQuery = graphql`
query ImagesForGallery {
  allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
    edges {
      node {
        childImageSharp {
          thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
          full: gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}

`

export default GalleryImages